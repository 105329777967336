import axios from 'axios'

const API_PREFIX = "movimientos-devoluciones-estadisticas";

const ENDPOINTS = {
    semanasRetiros: params => axios(`${API_PREFIX}/semanas-retiros`, { params }),
    informeRetiros: params => axios(`${API_PREFIX}/informe-retiros`, { params }),
    informeRetirosLeechero: params => axios(`${API_PREFIX}/informe-retiros-leechero`, { params }),
};

export default ENDPOINTS
