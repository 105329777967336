<template>
    <section class="px-4">
        <p class="text-general f-18 f-600">
            Devoluciones cedis de productos en las últimas 20 semanas
        </p>
        <div class="d-flex mx-0 pt-5 position-relative">
            <cargando v-if="graficasSemanas" />
            <div class="col-5">
                <p class="text-center text-general mb-3 f-17 f-600 d-middle-center" style="height:43px;">
                    Motivos de traslados cedis
                </p>
                <echart :options="motivos_torta" />
                <el-popover
                v-model="visibleDineroMotivos"
                popper-class="popoverEdit"
                placement="top"
                trigger="manual"
                >
                    <div class="row justify-center mx-0 position-relative">
                        <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleDineroMotivos = !visibleDineroMotivos" />
                        Motivos
                    </div>
                    <div v-for="(activo, r) in motivos" :key="r" class="row mx-2 justify-content-center py-2">
                        <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                        <div class="col">
                            {{ activo.name }}
                        </div>
                        <div class="col-auto">
                            {{ agregarSeparadoresNumero(activo.percent,1) }}%
                        </div>
                    </div>
                    <div v-if="motivos.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleDineroMotivos = !visibleDineroMotivos">
                        Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                    </div>
                </el-popover>
            </div>
            <div class="col">
                <div class="row mx-0 justify-center mb-3" style="height:43px;">
                    <div class="bg-light-f5 text-general py-2 br-12 px-3 py-2 shadow f-18">
                        Valor de los retiros
                        <b class="ml-3">
                            {{ separadorNumero(retiros.total) }}
                        </b>
                    </div>
                    <div class="bg-light-f5 text-general py-2 br-12 px-3 py-2 shadow f-18 ml-4">
                        Total retiros
                        <b class="ml-4">
                            {{ agregarSeparadoresNumero(retiros.cantidad) }}
                        </b>
                    </div>
                </div>
                <echart :options="motivos_barras" />
                <div v-if="_.sumBy(motivos_barras.dataset.source,'valor') == 0 && _.sumBy(motivos_barras.dataset.source,'retiros') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                <div v-if="_.sumBy(motivos_barras.dataset.source,'valor') == 0 && _.sumBy(motivos_barras.dataset.source,'retiros') == 0" class="img-sindatos text-center">
                    <img class="obj-cover" src="/img/otros/no_datos.svg" />
                </div>
            </div>
        </div>
        <div class="row mx-0 align-items-center mt-3">
            <div class="col-auto d-middle text-general f-15">
                Rango de análisis
            </div>
            <div class="col-auto" style="width:174px;">
                <el-select v-model="rango" placeholder="Seleccionar" size="small">
                    <el-option
                    v-for="item in rangos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 mx-2" style="width:248px;">
                <div class="date-picker-edit">
                    <el-date-picker
                    v-model="fecha"
                    class="w-100"
                    size="small"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    :disabled="disable_fecha"
                    popper-class="date-picker-edit-popper"
                    type="daterange"
                    unlink-panels
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-auto px-0">
                <div class="btn-general px-3" @click="informe">
                    Mostrar Informe
                </div>
            </div>
            <cargando v-if="cargandoInforme" mini />
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0 text-general align-items-center">
            <p class="col f-18 f-600">
                {{ $config.vendedor }} con mayor numero de devoluciones de productos
            </p>
            <div class="col-auto px-2">
                <span class="mx-2">Motivo</span>
                <el-select v-model="id_motivo" clearable size="small" @change="informe">
                    <el-option
                    v-for="item in motivosSelect"
                    :key="item.id"
                    :label="item.motivo"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-12 pt-4">
                <echart :options="cedis_mayor_retiros" />
                <div v-if="_.sumBy(cedis_mayor_retiros.dataset.source,'total') == 0 && _.sumBy(cedis_mayor_retiros.dataset.source,'cantidad') == 0" class="position-absolute h-100 w-100" style="top:0px;left:0px;opacity:0.8;filter:blur(6px);background-color:#ffffff;z-index:1;" />
                <div v-if="_.sumBy(cedis_mayor_retiros.dataset.source,'total') == 0 && _.sumBy(cedis_mayor_retiros.dataset.source,'cantidad') == 0" class="img-sindatos text-center">
                    <img class="obj-cover" src="/img/otros/no_datos.svg" />
                </div>
            </div>
            <div class="col-12 pt-4">
                <div class="row mx-0 justify-content-end px-5">
                    <div class="bg-general3 text-white br-20 px-3 cr-pointer mr-5" @click="verMas('Vendedores con mayor numero de devoluciones de productos', cedis)">
                        Ver más {{ $config.vendedor }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0">
            <p class="col-12 text-general f-600 f-18 mb-4">
                {{ $config.vendedor }} que más productos Devuelven
            </p>
            <div v-for="(data, d) in cedis" :key="d" class="tres-puntos px-0 mb-3" style="width:130px;">
                <div class="row mx-0 justify-center">
                    <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                </div>
                <p class="text-general tres-puntos pt-2 pb-1 f-500 text-center f-17">{{ agregarSeparadoresNumero(data.cantidad) }}</p>
                <p class="text-general tres-puntos f-16 text-center"> {{ separadorNumero(data.total) }} </p>
                <p class="text-general tres-puntos f-16 text-center"><i> {{ data.nombre }} </i></p>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0">
            <p class="col-12 text-general f-600 f-18 mb-4">
                CEDIS que más productos Recibe de otros {{ $config.vendedor }}
            </p>
            <div v-for="(data, d) in cedisDestino" :key="d" class="tres-puntos px-0 mb-3" style="width:130px;">
                <div class="row mx-0 justify-center">
                    <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                </div>
                <p class="text-general tres-puntos pt-2 pb-1 f-500 text-center f-17">{{ agregarSeparadoresNumero(data.cantidad) }}</p>
                <p class="text-general tres-puntos f-16 text-center"> {{ separadorNumero(data.total) }} </p>
                <p class="text-general tres-puntos f-16 text-center"><i> {{ data.nombre }} </i></p>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div class="row mx-0">
            <p class="col text-general f-600 f-18 mb-4">
                Productos con más devoluciones por todos los {{ $config.vendedor }}
            </p>
            <div class="col-auto">
                <div class="bg-general3 text-white px-3 br-20 cr-pointer" @click="verMas('Productos con más devoluciones por todos los Vendedores', productosOriginal)">
                    Ver más productos
                </div>
            </div>
            <div class="col-12 px-0">
                <div class="row mx-0">
                    <div v-for="(data, d) in productos" :key="d" class="tres-puntos px-0 mb-3" style="width:130px;">
                        <div class="row mx-0 justify-center">
                            <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                                <img :src="data.foto" width="115" height="115" class="obj-cover border br-12" />
                            </el-tooltip>
                        </div>
                        <p class="text-general tres-puntos f-16 pt-2 pb-1 text-center">{{ agregarSeparadoresNumero(data.cantidad) }}</p>
                        <p class="text-general tres-puntos f-16 text-center f-500 f-17"> {{ separadorNumero(data.total) }} </p>
                        <p class="text-general tres-puntos f-16 text-center"> {{ agregarSeparadoresNumero(data.porcentaje_cantidad,1) }} %</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 py-4" />
        <div v-show="$can('ver_estadisticas_movimientos_informe_especifico')" class="row mx-0 position-relative">
            <cargando v-if="graficasInformeCedis" />
            <p class="col-12 text-general f-18 f-600 mb-3">
                Análisis de retiros por {{ $config.vendedor }}
            </p>
            <div class="col-12 d-middle">
                <div class="col-auto text-general">
                    {{ $config.vendedor }}
                </div>
                <div class="col-auto">
                    <img :src="fotoUsurio" width="50" height="50" class="obj-cover rounded-circle" />
                </div>
                <div class="col-3">
                    <el-select
                    v-model="id_leechero"
                    filterable
                    clearable
                    remote
                    class="w-100"
                    reserve-keyword
                    :remote-method="remoteMethod"
                    @change="informeRetirosLeechero"
                    >
                        <el-option
                        v-for="item in leecherosSelect"
                        :key="item.id"
                        :label="`${item.nombre} - ${item.nombre_cedis}`"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <template v-if="id_leechero != null">
                <div class="col-4">
                    <p class="text-general f-18 f-600 text-center mt-4">Devoluciones</p>
                    <p class="text-general f-18 f-600 text-center mt-2">{{ _.sumBy(motivos_torta_informe.dataset.source,'cantidad') }}</p>
                    <echart :options="motivos_torta_informe" />
                    <el-popover
                    v-model="visibleDineroMotivosInforme"
                    popper-class="popoverEdit"
                    placement="top"
                    trigger="manual"
                    >
                        <div class="row justify-center mx-0 position-relative">
                            <i class="icon-cancel text-general cr-pointer position-absolute f-18" style="right:0px;top:-3px;" @click="visibleDineroMotivosInforme = !visibleDineroMotivosInforme" />
                            Motivos
                        </div>
                        <div v-for="(activo, r) in motivosInforme" :key="r" class="row mx-2 justify-content-center py-2">
                            <div style="width:14px;height:14px;border-radius:50%;" :style="`backgroundColor:${activo.color}`" />
                            <div class="col">
                                {{ activo.name }}
                            </div>
                            <div class="col-auto">
                                {{ agregarSeparadoresNumero(activo.percent,1) }}%
                            </div>
                        </div>
                        <div v-if="motivosInforme.length" slot="reference" class="row justify-center text-g-light f-15 cr-pointer" @click="visibleDineroMotivosInforme = !visibleDineroMotivosInforme">
                            Ver más <i class="icon-angle-up text-general f-15 cr-pointer" />
                        </div>
                    </el-popover>
                </div>
                <div class="col pt-3">
                    <div class="row mx-0 mb-4">
                        <p class="col text-general f-500 f-18">
                            Productos con más retiros por este vendedor
                        </p>
                        <div class="col-auto">
                            <div class="bg-general3 text-white br-20 px-2 cr-pointer" @click="verMas('Productos con más retiros por este vendedor', productosInformeOriginal)">
                                Ver más productos
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div v-for="(data, d) in productosInforme" :key="d" class="tres-puntos mb-2" style="width:130px;">
                            <el-tooltip placement="bottom" effect="light" :content="data.nombre">
                                <img :src="data.foto" width="116" height="116" class="obj-cover br-12 border mx-1 my-1" />
                            </el-tooltip>
                            <p class="text-general tres-puntos text-center f-16 py-2">{{ agregarSeparadoresNumero(data.cantidad) }}</p>
                            <p class="text-general tres-puntos text-center f-17 f-600"> {{ separadorNumero(data.total) }} </p>
                            <p class="text-general tres-puntos text-center f-16 py-2"> {{ agregarSeparadoresNumero(data.porcentaje_cantidad,1) }} %</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- Partials -->
        <modal-ver-mas-movimientos ref="modalVerMasMovimientos" :titulo="tituloModal" />
    </section>
</template>

<script>
import Vue from 'vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DevolucionesLeecheros from "~/services/movimientos_devoluciones_estadisticas"
import Accesos from "~/services/accesos_estadisticas"

Vue.component('echarts',echarts);

let color = [ '#A78FE2', '#F1BC8D', '#72DCCF', '#F1E1AB', '#8592A4', '#71C1EC', '#D382C7', '#79BB88', '#9FDDF8', '#F171D8', '#7797F1', '#DC7279', '#B78471', '#76A0B8', '#87DC72', ];

let colorGeneral = getComputedStyle(document.documentElement).getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement).getPropertyValue('--color-db')

export default {
    components: {
        modalVerMasMovimientos: () => import('../partials/verMasMovimientos')
    },
    data(){
        let self = this
        return{
            tituloModal: '',
            id_motivo: null,
            id_leechero: null,
            rangos: [
                { id: 1, nombre: "Hoy"},
                { id: 2, nombre: "Ayer"},
                { id: 3, nombre: "Última semana"},
                { id: 4, nombre: "Semana anterior"},
                { id: 5, nombre: "Último mes"},
                { id: 6, nombre: "Mes anterior"},
                { id: 7, nombre: "Último año"},
                { id: 8, nombre: "Personalizado"},
            ],
            rango: 5,
            fecha: [moment().subtract(1, 'month').toDate(),new Date()],
            disable_fecha: true,
            visibleDineroMotivos: false,
            visibleDineroMotivosInforme: false,
            graficasSemanas: false,
            graficasInformeCedis: false,
            cargandoInforme: false,
            // url_cedis: 'https://www.elpais.com.co/files/article_main/uploads/2020/02/07/5e3e133bd07ea.jpeg',
            // url_leechero: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            // url_producto: 'https://img.lalr.co/cms/2017/04/12033800/Productos_Vendidos_CocaCola1204.jpg?size=xl&ratio=r40_21',
            // url_image: 'https://img.freepik.com/foto-gratis/joven-confiado_1098-20868.jpg?size=626&ext=jpg',
            motivos_torta: {
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            motivos_torta_informe: {
                color: color,
                tooltip: {
                    trigger: 'item',
                    formatter: function(params){
                        return `${params.value.nombre}: ${self.agregarSeparadoresNumero(params.value.cantidad)} (${params.percent}%)`
                    }
                },
                dataset:{
                    dimensions:['nombre','cantidad'],
                    source:[],
                },
                series: [
                    {
                        name: 'Motivos',
                        type: 'pie',
                        center: ['50%', '50%'],
                        radius: ['50%', '70%'],
                        label:{
                            show: false,
                            color: '#707070'
                        },
                        labelLine:{
                            lineStyle:{
                                color: '#707070'
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            motivos_barras: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.semana}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.valor)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.retiros)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de movimientos", "Movimientos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['semana', 'valor','retiros'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de movimientos',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Movimientos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            cedis_mayor_retiros: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    backgroundColor: '#F5F5F5',
                    borderColor: '#F5F5F5',
                    formatter: function(obj){
                        // var value = obj.value;
                        return '<div style="border-bottom: 1px solid rgba(0,0,0,.3); font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">'
                        + `<p class="text-general2 text-center" >${obj[0].data.nombre}</p>`
                        + '</div>'
                        + '<div class="" style="min-width:150px" >'
                        +  `<i class="icon-blank f-18" style="color:${obj[0].color}" /><span class="text-general2 f-14">${self.separadorNumero(obj[0].data.total)} ${obj[0].seriesName} </span>` + '<br>'
                        +  `<i class="icon-brightness f-18" style="color:${obj[1].color}" /><span class="text-general2 f-14">${self.agregarSeparadoresNumero(obj[1].data.cantidad)} ${obj[1].seriesName} </span>` + '<br>'
                        + '</div>'
                    }
                },
                legend: {
                    data: ["Valor de movimientos", "Movimientos",]
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: true,
                        show:false
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        // name: 'Valor',
                    },
                    {
                        type: 'value',
                        scale: true,
                        show: false
                        // name: 'Pedidos',
                    }
                ],
                dataset:{
                    dimensions:['nombre', 'total','cantidad'],
                    source: []
                },
                series: [
                    {
                        name: 'Valor de movimientos',
                        type: 'bar',
                        barMaxWidth: 30,
                        // data: [120, 132, 101, 134, 90, 230, 210],
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB',
                            },
                        },
                    },
                    {
                        name: 'Movimientos',
                        // data: [220, 453, 301, 354, 290, 330, 320],
                        symbol: 'circle',
                        yAxisIndex: 1,
                        symbolSize: 10,
                        type: 'line',
                        itemStyle: {
                            normal: {
                                color: colorGeneral,
                            },
                        },
                    },
                ]
            },
            retiros: {
                total: 0,
                cantidad: 0,
            },
            semanas: [],
            motivos: [],
            motivosSelect: [],
            cedis: [],
            cedisDestino: [],
            productos: [],
            motivosInforme: [],
            productosInforme: [],
            productosOriginal: [],
            productosInformeOriginal: [],
            leecherosSelect: [],
        }
    },
    computed: {
        ...mapGetters({
            id_moneda:'cedis/id_moneda',
            cedisNavbar:'cedis/cedis',
            id_cedis:'cedis/id_cedis',
        }),
        cedisCopy(){
            return this.cedisNavbar.filter(el => el.id != 0)
        },
        cargandoTerminado(){
            return  !this.graficasSemanas &&
                    !this.cargandoInforme
        },
        fotoUsurio(){
            let id = this.id_leechero
            let usuarios = this.leecherosSelect


            let usuario = usuarios.find(el => el.id == id)
            return usuario ? usuario.imagen : '/img/no-imagen/sin_user.png'
        },
    },
    watch: {
        rango(val){
            switch (val){
            case 1:
                this.fecha = []
                this.fecha[0] = new Date()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 2:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'day').toDate()
                this.fecha[1] = moment().subtract(1, 'day').toDate()
                this.disable_fecha = true
                break;
            case 3:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 4:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'week').startOf('week').toDate()
                this.fecha[1] = moment().subtract(1, 'week').endOf('week').toDate()
                this.disable_fecha = true
                break;
            case 5:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 6:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'month').startOf('month').toDate()
                this.fecha[1] = moment().subtract(1, 'month').endOf('month').toDate()
                this.disable_fecha = true
                break;
            case 7:
                this.fecha = []
                this.fecha[0] = moment().subtract(1, 'year').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = true
                break;
            case 8:
                this.fecha = []
                this.fecha[0] = moment().startOf('month').toDate()
                this.fecha[1] = new Date()
                this.disable_fecha = false
                break;

            default:
                break;
            }
        },
        id_moneda(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
        id_cedis(val,oldVal){
            if(oldVal == null) return
            this.cargando()
            this.montar()
        },
    },
    mounted(){
        this.montar()
    },
    methods: {
        verMas(titulo, array){
            this.tituloModal = titulo;
            let fecha_inicio =  moment(this.fecha[0]).format('Y-MM-DD')
            let fecha_fin =  moment(this.fecha[1]).format('Y-MM-DD')
            let fecha = `${this.formatearFecha(fecha_inicio,'D MMM YYYY')} - ${this.formatearFecha(fecha_fin,'D MMM YYYY')}`
            this.$refs.modalVerMasMovimientos.toggle(array, fecha);
        },
        async remoteMethod(query){
            try {
                let params = {
                    id_cedis: this.id_cedis,
                    tipo:1,
                    query,
                }
                const { data } = await Accesos.listaLeecheros(params)
                this.leecherosSelect = data
            } catch (e){
                this.leecherosSelect = [];
            }
        },
        cargando(informes = true){
            if (informes){
                this.graficasSemanas = true

            }
            this.cargandoInforme = true
            this.graficasInformeCedis = true
        },
        async montar(){
            try {
                if(this.id_moneda === null && this.id_cedis == null){
                    setTimeout(async() => {
                        await this.semanasRetiros()
                        await this.informeRetiros()
                        await this.informeRetirosLeechero()
                    }, 1000);
                }else{
                    await this.semanasRetiros()
                    await this.informeRetiros()
                    await this.informeRetirosLeechero()
                }
            } catch (error){
                console.log(error)
            }
        },

        async semanasRetiros(){
            try {
                let params = {
                    id_cedis: this.id_cedis,
                    // id_leechero: this.id_leechero,
                    id_moneda: this.id_moneda,
                }
                this.graficasSemanas = true

                const { data } = await DevolucionesLeecheros.semanasRetiros(params)

                this.motivos_torta.dataset.source = data.motivos
                this.motivos_barras.dataset.source = data.semanas
                this.retiros = data.retiros

                let arrayMotivos = []

                let motivos = data.motivos
                for (var i = 0; i < motivos.length; i++){
                    arrayMotivos.push({
                        value: motivos[i].cantidad,
                        name: motivos[i].nombre,
                        percent: motivos[i].porcentaje_cantidad,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },
                    });
                }
                this.motivos = arrayMotivos


            } catch (error){
                console.log(error)
            } finally {
                this.graficasSemanas = false
            }
        },
        async informeRetiros(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_cedis: this.id_cedis,
                    id_motivo: this.id_motivo,
                }
                this.cargandoInforme = true

                const {data} = await DevolucionesLeecheros.informeRetiros(params)

                this.cedis_mayor_retiros.dataset.source = data.leecheros
                this.cedis = data.leecheros
                this.cedisDestino = data.cedisDestino
                this.productosOriginal = data.productos
                this.productos = this.productosOriginal.slice(0,10)
                this.motivosSelect = data.motivos

            } catch (error){
                console.log(error)
            } finally {
                this.cargandoInforme = false
            }
        },
        async informeRetirosLeechero(){
            try {
                let params = {
                    fecha_inicio: moment(this.fecha[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.fecha[1]).format('Y-MM-DD'),
                    id_moneda: this.id_moneda,
                    id_leechero: this.id_leechero,
                    id_motivo: this.id_motivo,
                }
                this.graficasInformeCedis = true

                const {data} = await DevolucionesLeecheros.informeRetirosLeechero(params)

                this.motivos_torta_informe.dataset.source = data.motivosInforme

                let arrayMotivos = []

                let motivos = data.motivosInforme
                for (var i = 0; i < motivos.length; i++){
                    arrayMotivos.push({
                        value: motivos[i].cantidad,
                        name: motivos[i].nombre,
                        percent: motivos[i].porcentaje_cantidad,
                        color: color[i],
                        itemStyle: {
                            normal: {
                                borderWidth: 22,
                                borderColor: color[i]
                            }
                        },
                    });
                }
                this.motivosInforme = arrayMotivos
                this.productosInformeOriginal = data.productosInforme
                this.productosInforme = this.productosInformeOriginal.slice(0,12)

            } catch (error){
                console.log(error)
            } finally {
                this.graficasInformeCedis = false
            }
        },
        async informe(){
            try {
                this.cargando(false)
                await this.informeRetiros()
                await this.informeRetirosLeechero()
            } catch (error){
                console.log(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.img-sindatos{
        position: absolute;
        z-index: 3;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0%;
        left: 0px;
        width: 100%;
        height: 100%;
        img{
            // border-radius: 50%;
            /* max-height: 100%; */
            box-shadow: 0px 3px 6px #00000029;
            max-width: 50%;
            height: 50%;
        }
        .mensaje{
            color: #fff;
            text-shadow: 0 2px 3px #000000;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
